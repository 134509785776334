<template>
  <div class="tree-div">
      <Tree :data="treeData" show-checkbox ref="tree"></Tree>
  </div>
</template>

<script>
export default {
    props:{
        infoId:String,
        functionType:String
    },
    data(){
        return{
            treeData:[]
        }
    },
   async created(){
       await this.getChangeMenuCodeTree(this.infoId,this.functionType)
      
    },
    methods:{
      // 方法部分
      // 格式化数据
      formatterData(list){
          if(list && list.length){
               list.map(item =>{
                  if(item.children && item.children.length || item.subColumnCode){
                      item.disabled = true
                      this.formatterData(item.children)
                  }
              })
          }
      },
      // 接口部分 
      // 获取同步栏目接口
      async getChangeMenuCodeTree(infoId,functionType){
         return await this.$get('/old/api/pc/menu/getLayUiMenuTreesByFunctionType',{
          functionType,
        //   infoId,
            orgCode:parent.vue.loginInfo.userinfo.orgCode,
          staffId:parent.vue.loginInfo.userinfo.id,

         }).then(res => {
            if(res.code == 200){
                this.treeData = res.dataList
                 this.formatterData(this.treeData)
            }else{
                this.$Message.error({
                    content:'获取栏目数百',
                    background:true
                })
            }
         })

      }
    }
}
</script>

<style lang="less" scoped>
.tree-div{
    height: 500px;
    overflow-y: scroll;
}
/deep/.ivu-tree-arrow-disabled{
    cursor: pointer;
}
</style>